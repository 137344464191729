import React from 'react';

const Map = () => {
  return (
    <div className="map">
      <iframe src="https://maps.google.com/maps?hl=fr&amp;q=troyes,%20france&amp;t=&amp;z=6&amp;ie=UTF8&amp;output=embed" height="500" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Map