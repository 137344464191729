import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
// import TopNav from 'components/Navbars/TopNav-7.jsx';
import Navbar from 'components/Navbars/VoipServiceNav';
import Community from 'components/Contact/Community';
// import Form from 'components/Contact/Form';
import Map from 'components/Contact/Map';
import Footer from 'components/ITCreative/Footer';

const PageContactApp = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      {/* <TopNav style="4" /> */}
      <Navbar navbarRef={navbarRef} />
      <main className="contact-page style-5">
        <Community />
        {/* <Form /> */}
        <Map />
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>VOIPServices - Contact</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default PageContactApp;